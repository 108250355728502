import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const GraphLabel = () => {
  return (
    <>
      <Row className='d-flex justify-space-between lead mb-3'>
        <Col xs='1' className='d-flex justify-content-center' style={{ fontWeight: '100' }}>Key</Col>
        <Col xs='1' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>i</Col>
        <Col xs='1' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>j</Col>
        <Col xs='1' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>k</Col>
        <Col xs='1' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>Axis</Col>
        <Col xs='2' className='d-flex justify-content-center lead' style={{ fontWeight: '100' }}>Dominant</Col>
        <Col xs='2' className='d-flex justify-content-left lead' style={{ fontWeight: '100' }}>Color</Col>
      </Row>
    </>
  )
}

export default GraphLabel
