import React from 'React'
import * as d3 from 'd3'

export default class GraphLegend extends React.Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  render () {
    const yscale = d3.scaleLinear()
      .domain([-1, this.props.lines.length + 1])
      .range([0, this.props.h])
    const ents = this.props.lines.map((l, i) => {
      return (
        <g key={'legendEnt' + i}>
          <circle
            cx='5'
            cy={parseInt(yscale(i))}
            r='3'
            fill={l.hexColor}
          />
          <text
            x='15'
            y={parseInt(yscale(i)) + 4}
            fontSize='9px'
            fontFamily='sans-serif'
          > {l.key}
          </text>
          {(this.props.vals && this.props.vals.length > i)
            ? <text
                key={'legendVal' + i}
                x='45'
                y={parseInt(yscale(i)) + 4}
                fontSize='9px'
                fontFamily='sans-serif'
              > 
                {this.props.vals[i].toPrecision(5) / 1}
              </text>
            : null}
        </g>)
      })
    return (
      <svg
        x={this.props.x}
        y={this.props.y}
      >
        {ents}
      </svg>
    )
  }
}
